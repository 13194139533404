import React from 'react'
import Page from 'src/components/Page'
import IndexLayout from 'src/layouts'

const About = () => {
  return (
    <IndexLayout>
      <Page>
        <div>hello</div>
      </Page>
    </IndexLayout>
  )
}

export default About
